import { VtxAlert } from '@vertexinc/vtx-ui-react-component-library';
import React from 'react';
import 'src/components/alerts/error-alert.scss';

interface IErrorAlert {
  errors: string;
  onClose: () => void;
  message?: string;
}

export const ErrorAlert = (props: IErrorAlert) => {
  const { message = 'Correct the following errors:', errors, onClose } = props;

  const errorsList =
    errors !== message ? (
      <ul data-testid="error-alert-list" className="error-alert__list">
        {Array.isArray(errors) ? (
          errors.map((text) => {
            return (
              <li data-testid="error-alert-list-item" key={text}>
                {text}
              </li>
            );
          })
        ) : (
          <li>{errors}</li>
        )}
      </ul>
    ) : null;

  return (
    <VtxAlert
      data-testid="error-alert"
      message={message}
      description={errorsList}
      type="error"
      closable
      onClose={onClose}
      className="error-alert"
    />
  );
};
