import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { OrganizationOrInvalid, UserOrRefreshingOrSessionEnded } from './auth/authorize';
import { PATHS } from './constants';
import { Header } from './header/header';
import { IUserInfo } from './models/user-info';
import { CustomerManagement } from './pages/customer-management/customer-management';
import { AccessDenied } from './pages/errors/access-denied';
import { Error } from './pages/errors/error';
import { NotFound } from './pages/errors/not-found';
import { LoggedOut } from './pages/logged-out/logged-out';
import { SideMenu } from './side-menu/side-menu';

interface OwnProps {
  user: UserOrRefreshingOrSessionEnded;
  userInfo: IUserInfo | null;
  organization: OrganizationOrInvalid;
}

export const Routes = (props: OwnProps) => {
  const { user, userInfo, organization } = props;
  const isOrgKnown = !!organization;

  return (
    <Router>
      <Header user={user} organization={organization} />
      {isOrgKnown && userInfo != null && (
        <SideMenu loginUser={{ user, userInfo }} organization={organization} />
      )}
      <div className={userInfo ? 'app-container' : 'app-container app-container__error'}>
        <Switch>
          {isOrgKnown && userInfo != null && (
            <>
              <Route path={PATHS.CUSTOMER_INDEX}>
                <CustomerManagement loginUser={{ user, userInfo }} organization={organization} />
              </Route>
            </>
          )}
          <Route path={PATHS.ERROR}>
            <Error organization={organization} />
          </Route>
          <Route path={PATHS.NOT_FOUND}>
            <NotFound organization={organization} />
          </Route>
          <Route path={PATHS.FORBIDDEN}>
            <AccessDenied organization={organization} />
          </Route>
          <Route path={PATHS.LOGGED_OUT_ROUTE}>
            <LoggedOut />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};
