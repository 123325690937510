import {
  VtxBreadcrumb,
  VtxBreadcrumbItem,
  VtxLoadingIndicator,
  VtxTab,
  VtxTabList,
  VtxTabPanel,
  VtxTabPanels,
  VtxTabs,
  VtxTitle,
} from '@vertexinc/vtx-ui-react-component-library';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath, Link, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { CustomerService } from 'src/api/customers';
import { PATHS, QUERY_KEYS } from 'src/constants';
import { ICustomer, IVodSubDomain } from 'src/models/customer';
import { IOrganization } from 'src/models/organization';
import { CustomerConnections } from 'src/pages/customer-management/details/connections/customer-connections';
import { CustomerOwnedEmailDomains } from 'src/pages/customer-management/details/customer-owned-email-domains/customer-owned-email-domains';
import { CustomerUsers } from 'src/pages/customer-management/details/users/customer-users';
import { CustomerVodSubDomains } from 'src/pages/customer-management/details/vod-sub-domains/customer-vod-sub-domains';
import { IEntityParams, ILoginUser } from 'src/types';
import { VodSubDomainsService } from '../../../api/vod-sub-domains';

enum Tab {
  CustomerOwnedEmailDomains = 'Customer-Owned Email Domains',
  VODSubDomains = 'VOD Sub-Domains',
  Users = 'Users',
  Connections = 'Connections',
}

interface OwnProps {
  loginUser: ILoginUser;
  organization: IOrganization;
}

export const CustomerDetailsPage = (props: OwnProps) => {
  const { organization: orgName, loginUser } = props;
  const { id }: IEntityParams = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>();
  const { pathname } = useLocation();
  const history = useHistory();

  const customerOwnedEmailDomainsTabPath = generatePath(PATHS.CUSTOMER_VIEW, { orgName, id });
  const vodSubDomainsTabPath = generatePath(PATHS.CUSTOMER_VIEW_VOD_DOMAINS, { orgName, id });
  const usersTabPath = generatePath(PATHS.CUSTOMER_VIEW_USERS, { orgName, id });
  const connectionsTabPath = generatePath(PATHS.CUSTOMER_VIEW_CONNECTIONS, { orgName, id });

  const customerQuery = useQuery<ICustomer, Error>(
    [QUERY_KEYS.CUSTOMER, id],
    () => CustomerService.getCustomer({ id }),
    {
      onError: (err: Error) => {
        throw err;
      },
    }
  );

  const vodSubDomainsQuery = useQuery<IVodSubDomain[], Error>(
    [QUERY_KEYS.VOD_SUBDOMAINS],
    () => VodSubDomainsService.getVodSubdomains({ customerId: id }),
    {
      onError: (err: Error) => {
        throw new Error(
          'An error occurred retrieving client information.  Please try again and contact support if the problem persists.'
        );
      },
    }
  );

  const changeTab = (key: number) => {
    setActiveTabIndex(key);
    switch (key) {
      case 1:
        history.push(vodSubDomainsTabPath);
        break;
      case 2:
        history.push(usersTabPath);
        break;
      case 3:
        history.push(connectionsTabPath);
        break;
      default:
        history.push(customerOwnedEmailDomainsTabPath);
        break;
    }
  };

  useEffect(() => {
    let index = 0;
    if (pathname === vodSubDomainsTabPath) {
      index = 1;
    } else if (pathname === usersTabPath) {
      index = 2;
    } else if (pathname === connectionsTabPath) {
      index = 3;
    }
    setActiveTabIndex(index);
  }, [pathname, id, orgName]);

  const { data: customer, isLoading } = customerQuery;
  const { data: vodSubDomains } = vodSubDomainsQuery;

  if (!customer || isLoading) {
    return <VtxLoadingIndicator />;
  }

  return (
    <>
      <VtxBreadcrumb>
        <VtxBreadcrumbItem>
          <Link to={generatePath(PATHS.CUSTOMER_INDEX, { orgName })}>Customer Management</Link>
        </VtxBreadcrumbItem>
        <VtxBreadcrumbItem aria-current="page" data-testid="breadcrumb-manage-customer-details">
          Manage Customer Details
        </VtxBreadcrumbItem>
      </VtxBreadcrumb>
      <VtxTitle h1Text={customer.customerName} />
      <VtxTabs index={activeTabIndex} defaultIndex={0} onChange={changeTab}>
        <VtxTabList>
          <VtxTab data-testid="customer-owned-email-domains-tab">{Tab.CustomerOwnedEmailDomains}</VtxTab>
          <VtxTab data-testid="customer-vod-subdomains-tab">{Tab.VODSubDomains}</VtxTab>
          <VtxTab data-testid="customer-users-tab">{Tab.Users}</VtxTab>
          <VtxTab data-testid="customer-connections-tab">{Tab.Connections}</VtxTab>
        </VtxTabList>
        <VtxTabPanels className="customer-tabs-panels">
          <VtxTabPanel>
            <Switch>
              <Route path={PATHS.CUSTOMER_VIEW}>
                <CustomerOwnedEmailDomains />
              </Route>
            </Switch>
          </VtxTabPanel>
          <VtxTabPanel>
            <Switch>
              <Route path={PATHS.CUSTOMER_VIEW_VOD_DOMAINS} exact>
                <CustomerVodSubDomains
                  customer={customer}
                  loginUser={loginUser}
                  vodSubDomains={vodSubDomains}
                />
              </Route>
            </Switch>
          </VtxTabPanel>
          <VtxTabPanel>
            <Switch>
              <Route path={PATHS.CUSTOMER_VIEW_USERS} exact>
                <CustomerUsers />
              </Route>
            </Switch>
          </VtxTabPanel>
          <VtxTabPanel>
            <Switch>
              <Route path={PATHS.CUSTOMER_VIEW_CONNECTIONS} exact>
                <CustomerConnections />
              </Route>
            </Switch>
          </VtxTabPanel>
        </VtxTabPanels>
      </VtxTabs>
    </>
  );
};
