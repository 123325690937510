export enum UserRole {
  EnterprisePortalAdmin = 1,
  CustomerAdmin = 2,
}

export interface IUserInfo {
  firstName: string;
  lastName: string;
  createdUtc?: string;
  modifiedUtc?: string;
  versionId?: string;
  isAuth0Complete?: boolean;
  userId?: string;
  emailAddress: string;
  personId?: string;
  hasAcceptedInvitation?: boolean;
  userRoleId: UserRole;
}
