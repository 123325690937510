import { getLocationPathname } from 'src/dom-helpers/window-wrapper';
import { decodeJwt } from 'src/token-helpers/decode-jwt';

export const getOrgNameFromUrl = (): string | null => {
  const pathname = getLocationPathname();
  const segments = pathname.split('/');
  const orgName = segments[1];
  return !orgName ? null : orgName;
};

export const getOrgIdFromJwt = (accessToken: string): string | null => {
  const decodedJwt: { org_id: string } = decodeJwt(accessToken);
  return decodedJwt.org_id || null;
};

export const getOrgIdFromName = async (orgName: string): Promise<string | null> => {
  return Promise.resolve('org_wlhSqN2qmeQ8oVya');
};

export const getOrgNameFromId = async (orgId: string): Promise<string | null> => {
  return Promise.resolve('vertexinc');
};
