import { User, UserManager } from 'oidc-client';
import { PATHS } from 'src/constants';
import { IOrganization } from 'src/models/organization';
import { getLocationPathname, getLocationSearch, setLocationHref } from '../dom-helpers/window-wrapper';
import { getOrgIdFromJwt, getOrgIdFromName, getOrgNameFromId, getOrgNameFromUrl } from './get-organization';

export type UserOrRefreshingOrSessionEnded = User | 'currentlyRefreshing' | 'sessionEnded' | 'inError';

export type OrganizationOrInvalid = IOrganization | null;

const pathStartsWith = (expectedPrefix: string): boolean => {
  const pathNameLower = getLocationPathname().toLowerCase();
  return pathNameLower === expectedPrefix || pathNameLower.startsWith(`${expectedPrefix}/`);
};

const pathEqualsPrefix = (expectedPrefix: string): boolean => {
  const pathNameLower = getLocationPathname().toLowerCase();
  return pathNameLower === expectedPrefix;
};

export const authorize = async (
  userManager: UserManager
): Promise<{
  userOrRefreshing: UserOrRefreshingOrSessionEnded;
  organization: OrganizationOrInvalid;
}> => {
  const isUrlSpaRoot = pathEqualsPrefix(PATHS.ROOT);

  // If auth callback request, handle callback
  const isUrlAuthCallback = pathEqualsPrefix(PATHS.OIDC_REDIRECT_RELATIVE);
  if (isUrlAuthCallback) {
    const { state: redirectUrl, access_token } = await userManager.signinCallback();
    if (redirectUrl) {
      setLocationHref(redirectUrl);
      return { userOrRefreshing: 'currentlyRefreshing', organization: null };
    } else {
      // If no redirect URL, get org ID from JWT, then make API call to get org name, then
      // update pathname to include org name.
      const orgId = getOrgIdFromJwt(access_token);
      if (!orgId) {
        setLocationHref(PATHS.ERROR);
        return { userOrRefreshing: 'inError', organization: null };
      } else {
        const orgName = await getOrgNameFromId(orgId);
        if (!orgName) {
          setLocationHref(PATHS.ERROR);
          return { userOrRefreshing: 'inError', organization: null };
        } else {
          setLocationHref(`/${orgName}`);
          return { userOrRefreshing: 'currentlyRefreshing', organization: null };
        }
      }
    }
  }

  if (pathStartsWith(PATHS.LOGGED_OUT_ROUTE)) {
    return { userOrRefreshing: 'sessionEnded', organization: null };
  }

  const isUrlErrorPage =
    pathStartsWith(PATHS.ERROR) || pathStartsWith(PATHS.FORBIDDEN) || pathStartsWith(PATHS.NOT_FOUND);

  // Check if access token exists and hasn't expired.
  const orgNameFromUrl = getOrgNameFromUrl();
  const user = await userManager.getUser();
  if (user == null || user.expired) {
    if (isUrlErrorPage) {
      return { userOrRefreshing: 'inError', organization: null };
    }

    // If no valid session and no org in pathname, set org as invalid
    if (isUrlSpaRoot || !orgNameFromUrl) {
      setLocationHref(PATHS.ERROR);
      return { userOrRefreshing: 'inError', organization: null };
    }
    // Then make API call to get org ID
    const orgId = await getOrgIdFromName(orgNameFromUrl);
    if (!orgId) {
      setLocationHref(PATHS.ERROR);
      return { userOrRefreshing: 'inError', organization: null };
    }

    // Set attempted URL to state so it will be navigated to after authentication
    let redirectUrl = getLocationPathname();
    const queryString = getLocationSearch();
    if (!!queryString) {
      redirectUrl += queryString;
    }

    // Redirect to auth server
    await userManager.signinRedirect({
      state: redirectUrl,
      extraQueryParams: {
        ...userManager.settings.extraQueryParams,
        organization: orgId,
      },
    });
    return { userOrRefreshing: 'currentlyRefreshing', organization: null };
  }

  if (isUrlErrorPage) {
    return { userOrRefreshing: 'inError', organization: null };
  }

  // If valid user session, determine if there is a need to redirect
  const orgId = getOrgIdFromJwt(user.access_token);
  if (!orgId) {
    setLocationHref(PATHS.ERROR);
    return { userOrRefreshing: 'inError', organization: null };
  }
  const orgName = await getOrgNameFromId(orgId);
  if (!orgName) {
    setLocationHref(PATHS.ERROR);
    return { userOrRefreshing: 'inError', organization: null };
  }

  // If valid user, but no org in pathname, redirect to path with name of org from JWT.
  if (isUrlSpaRoot || orgName !== orgNameFromUrl) {
    setLocationHref(`/${orgName}`);
    return { userOrRefreshing: 'currentlyRefreshing', organization: orgName };
  }

  return { userOrRefreshing: user, organization: orgName };
};
