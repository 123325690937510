import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { DEFAULT_PAGE_SIZE, PATHS } from 'src/constants';
import { replaceUrl } from 'src/dom-helpers/window-wrapper';
import { SessionManager } from 'src/session-manager';

export function request<ResponseType>(url: string) {
  return new Request<ResponseType>(url);
}

export class RequestError extends Error {
  private readonly _cause: AxiosError;
  constructor(message: string, cause: AxiosError) {
    super(message);
    this._cause = cause;
  }

  get cause(): AxiosError {
    return this._cause;
  }
}

class Request<ResponseType> {
  private _url: string;
  private _sessionManager: SessionManager;
  private _config: AxiosRequestConfig;

  constructor(url: string) {
    this._url = url;
    this._sessionManager = SessionManager.getInstance();
    this._config = {
      headers: this.getBaseHeaders(),
    };
  }

  private getBaseHeaders() {
    const accessToken = this._sessionManager.getAccessToken();
    return {
      'Content-type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
  }

  public params<ParamsType>(params: ParamsType) {
    this._config.params = { ...this._config.params, ...params };
    return this;
  }

  public headers<HeadersType>(headers: HeadersType) {
    this._config.headers = { ...this._config.headers, ...headers };
    return this;
  }

  public config<ConfigType>(config: ConfigType) {
    this._config = { ...this._config, ...config };
    return this;
  }

  public async get(): Promise<ResponseType> {
    return axios
      .get(this._url, this._config)
      .then((response) => response.data)
      .catch((error: AxiosError) => this.handleError(error));
  }

  public async delete(): Promise<ResponseType> {
    return axios
      .delete(this._url, this._config)
      .then((response) => response.data)
      .catch((error: AxiosError) => this.handleError(error));
  }

  public async post<PostType>(data?: PostType): Promise<ResponseType> {
    return axios
      .post(this._url, data, this._config)
      .then((response) => (response.data ? response.data : response))
      .catch((error: AxiosError) => this.handleError(error));
  }

  public async put<PostType>(data: PostType): Promise<ResponseType> {
    return axios
      .put(this._url, data, this._config)
      .then((response) => response.data)
      .catch((error: AxiosError) => this.handleError(error));
  }

  private async handleError(error: AxiosError) {
    // Error
    let userMessage;
    window.console.log(error);
    if (error.response) {
      // The request was made and the server responded with a status code
      if (error.response.status === 403) {
        userMessage = 'Server responded with forbidden status.';
        replaceUrl(PATHS.FORBIDDEN);
      } else if (error.response.status === 401) {
        userMessage = 'Server responded with unauthorized status.';
        const logOut = this._sessionManager.getLogOutFn();
        await logOut();
      } else {
        userMessage = `Server responded with error. ${error.message}`;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      userMessage = `Request was sent but server was unable to respond. ${error.message}`;
    } else {
      // Something happened in setting up the request that triggered an Error
      userMessage = `Request could not be made to server. ${error.message}`;
    }
    throw new RequestError(userMessage, error);
  }
}

export const getPaginatedRequestParams = (input: {
  currentPage?: number;
  pageSize?: number;
  orderBy?: string;
  search?: string;
}): {
  $skip?: number;
  $top?: number;
} => {
  const { currentPage, pageSize, orderBy, search } = input;

  const top = pageSize || DEFAULT_PAGE_SIZE;
  const skip = top * ((currentPage || 1) - 1);

  const params = {
    $skip: skip,
    $top: top,
    $count: true,
  };

  if (orderBy) {
    params['$orderby'] = orderBy;
  }
  if (search) {
    params['$search'] = search;
  }

  return params;
};
