import { OidcClientSettings } from 'oidc-client';
import { PATHS } from 'src/constants';
import { getWrappedProcessEnv } from 'src/wrapped-process-env';
import { isOidcClientSettings } from './is-oidc-client-settings';

export const getAndValidateClientSettings = (): OidcClientSettings => {
  const { OIDC_CLIENT_SETTINGS } = getWrappedProcessEnv();
  if (!OIDC_CLIENT_SETTINGS) {
    throw new Error('client settings are not configured for this environment');
  }
  const clientSettings = parseOidcClientSettings(OIDC_CLIENT_SETTINGS);
  throwIfHasBadContent(clientSettings);
  return clientSettings;
};

export const throwIfHasBadContent = (clientSettings: OidcClientSettings): void => {
  if (clientSettings.post_logout_redirect_uri) {
    checkCorrectPostLogoutUri(clientSettings.post_logout_redirect_uri);
  } else {
    throw new Error('Could not find post_logout_redirect_uri on the client settings. It is required.');
  }
  if (clientSettings.redirect_uri) {
    checkCorrectAuthCallbackUri(clientSettings.redirect_uri);
  } else {
    throw new Error('Could not find redirect_uri on the client settings. It is required.');
  }
};

export const parseOidcClientSettings = (value: string): OidcClientSettings => {
  try {
    const parsedValue = JSON.parse(value) as OidcClientSettings;
    if (!isOidcClientSettings(parsedValue)) {
      throw new Error('Client settings are incomplete');
    }
    return parsedValue;
  } catch (err) {
    throw new Error('Client settings are not valid');
  }
};

/**
 * Makes sure that the consumer provides a valid post-logout uri. Otherwise the auth flows won't work at all.
 * @param testStr the string to verify the content of
 */
const checkCorrectPostLogoutUri = (testStr: string): void => {
  const expectedSuffix = PATHS.LOGGED_OUT_ROUTE;
  if (!testStr.endsWith(expectedSuffix)) {
    throw new Error(
      `Expected the value of post_logout_redirect_uri to end with ${expectedSuffix} but it did not. Instead it was: "${testStr}"`
    );
  }
};

/**
 * Makes sure that the consumer provides a valid auth callback uri. Otherwise the auth flows won't work at all.
 * @param testStr the string to verify the content of
 */
const checkCorrectAuthCallbackUri = (testStr: string): void => {
  const expectedSuffix = PATHS.OIDC_REDIRECT_RELATIVE;
  if (!testStr.endsWith(expectedSuffix)) {
    throw new Error(
      `Expected the value of redirect_uri to end with ${expectedSuffix} but it did not. Instead it was: "${testStr}"`
    );
  }
};
