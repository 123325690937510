import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATHS } from 'src/constants';
import { IOrganization } from 'src/models/organization';
import { CustomerAddPage } from 'src/pages/customer-management/add/add';
import { CustomerDetailsPage } from 'src/pages/customer-management/details/details';
import { CustomerIndexPage } from 'src/pages/customer-management/index/index';
import { ILoginUser } from 'src/types';

interface OwnProps {
  loginUser: ILoginUser;
  organization: IOrganization;
}

export const CustomerManagement = (props: OwnProps) => {
  const { organization: orgName, loginUser } = props;

  return (
    <Switch>
      <Route path={PATHS.CUSTOMER_INDEX} exact>
        <CustomerIndexPage loginUser={loginUser} organization={orgName} />
      </Route>
      <Route path={PATHS.CUSTOMER_ADD}>
        <CustomerAddPage loginUser={loginUser} organization={orgName} />
      </Route>
      <Route path={PATHS.CUSTOMER_VIEW}>
        <CustomerDetailsPage organization={orgName} loginUser={loginUser} />
      </Route>
    </Switch>
  );
};
