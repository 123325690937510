import { VtxButton } from '@vertexinc/vtx-ui-react-component-library';
import { User } from 'oidc-client';
import * as React from 'react';
import { OrganizationOrInvalid, UserOrRefreshingOrSessionEnded } from 'src/auth/authorize';
import { PUBLIC_APP_URL } from 'src/constants';
import logo from 'src/images/logo-vertex-inc-header.svg';
import { SessionManager } from 'src/session-manager';
import './header.scss';

interface OwnProps {
  user: UserOrRefreshingOrSessionEnded;
  organization: OrganizationOrInvalid;
}

export const Header = (props: OwnProps) => {
  const { user, organization } = props;
  const isOrgKnown = !!organization;
  const sessionManager = SessionManager.getInstance();

  const onSignOutClick = async () => {
    const logout = sessionManager.getLogOutFn();
    await logout();
  };

  const url = isOrgKnown ? `/${organization}` : PUBLIC_APP_URL;

  return (
    <header>
      <a href={url}>
        <img className="logo" src={logo} alt="Home" />
      </a>
      {user instanceof User && (
        <div className="user-info">
          <span className="user-name">
            {user.profile.given_name} {user.profile.family_name}
          </span>
          <VtxButton onClick={onSignOutClick}>Sign out</VtxButton>
        </div>
      )}
    </header>
  );
};
