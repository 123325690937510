import { VtxErrorSection } from '@vertexinc/vtx-ui-react-component-library';
import * as React from 'react';
import { OrganizationOrInvalid } from 'src/auth/authorize';
import { PUBLIC_APP_URL } from 'src/constants';

interface OwnProps {
  organization: OrganizationOrInvalid;
}

export const Error = (props: OwnProps) => {
  const { organization } = props;
  const url = organization ? `/${organization}` : PUBLIC_APP_URL;
  return <VtxErrorSection errorType="500" callToActionReturnUrl={url} />;
};
