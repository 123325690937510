import { VtxFormProps, VtxLoadingIndicator, VtxTitle } from '@vertexinc/vtx-ui-react-component-library';
import React, { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';
import { CustomerService } from 'src/api/customers';
import { ErrorAlert } from 'src/components/alerts/error-alert';
import { PATHS, QUERY_KEYS } from 'src/constants';
import { replaceUrl } from 'src/dom-helpers/window-wrapper';
import { ICustomer, ICustomerAdd, IVodSubDomain } from 'src/models/customer';
import { IOrganization } from 'src/models/organization';
import { CustomerAddForm, ICustomerAddForm } from 'src/pages/customer-management/add/add-form';
import { IError, ILoginUser, StatefulMessage } from 'src/types';
import { isVertexAdmin } from 'src/utils/role-helper';

interface OwnProps {
  loginUser: ILoginUser;
  organization: IOrganization;
}

export const CustomerAddPage: FC<OwnProps> = ({ loginUser, organization }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState();
  const history = useHistory();
  const queryClient = useQueryClient();
  const generalErrorMessage = 'The customer can not be created.';
  const canViewPage = isVertexAdmin(loginUser.userInfo);

  const { mutate: addCustomer } = useMutation(CustomerService.addCustomer, {
    onSuccess: (customer: ICustomer) => {
      setIsSubmitting(false);
      queryClient.invalidateQueries(QUERY_KEYS.CUSTOMERS);
      const message: StatefulMessage = {
        text: `The new customer ${customer.customerName} was successfully created. An invitation to ${
          customer.primaryContact?.emailAddress
            ? customer.primaryContact?.emailAddress
            : 'the primary contact'
        } was sent.`,
        type: 'success',
      };
      history.push(generatePath(PATHS.CUSTOMER_INDEX, { orgName: organization }), { message });
    },
    onError: (err: IError) => {
      setIsSubmitting(false);
      setError(err.cause?.response?.data.errorMessages || generalErrorMessage);
    },
  });

  const onCancel = () => {
    history.push(generatePath(PATHS.CUSTOMER_INDEX, { orgName: organization }));
  };

  const formValues = {
    customerName: '',
    organizationName: '',
    primaryContactEmailAddress: '',
    primaryContactFirstName: '',
    primaryContactLastName: '',
    emailDomains: [],
    vodSubDomains: [],
  };

  const onFinish: VtxFormProps<ICustomerAddForm>['onFinish'] = (customer) => {
    const {
      customerName,
      organizationName,
      primaryContactEmailAddress,
      primaryContactFirstName,
      primaryContactLastName,
      emailDomains,
      vodSubDomains,
    } = customer;
    setIsSubmitting(true);
    const newCustomer: ICustomerAdd = {
      customerName,
      organizationName,
      organizationDisplayName: customerName,
      primaryContact: {
        emailAddress: primaryContactEmailAddress,
        firstName: primaryContactFirstName,
        lastName: primaryContactLastName,
      },
      emailDomains: emailDomains.split(';').filter((i) => !!i),
      vodSubDomains: vodSubDomains
        .split(';')
        .filter((i) => !!i)
        .map((i) => ({
          subDomain: i,
        })),
    };
    addCustomer({ customer: newCustomer });
  };

  const resetError = () => setError(undefined);

  if (!canViewPage) {
    replaceUrl(PATHS.FORBIDDEN);
    return <VtxLoadingIndicator />;
  }

  return (
    <>
      <VtxTitle h1Text="Invite a Customer" />
      {error && (
        <ErrorAlert
          errors={error}
          onClose={resetError}
          message={error === generalErrorMessage ? generalErrorMessage : undefined}
        />
      )}
      <p className="content-area">
        Provide the organization name, customer name, primary contact first name, last name and address. Then
        provide domains and sub-domains.
      </p>
      <CustomerAddForm
        formName="invite-customer-form"
        formValues={{
          ...formValues,
          emailDomains: formValues.emailDomains.join(';'),
          vodSubDomains: formValues.vodSubDomains.map((i: IVodSubDomain) => i.subDomain).join(';'),
        }}
        submitBtnTitle="Invite Customer"
        isSubmitting={isSubmitting}
        onFinish={onFinish}
        onCancel={onCancel}
      />
    </>
  );
};
