import { UserManager } from 'oidc-client';

export type SignoutRedirectArgs =
  | {
      post_logout_redirect_uri: URL;
    }
  | undefined;

export const getSignoutRedirectArgs = (
  userManager: Readonly<UserManager>,
  isTimeOut?: boolean
): SignoutRedirectArgs => {
  const { post_logout_redirect_uri } = userManager.settings;
  if (!isTimeOut || !post_logout_redirect_uri) {
    return undefined;
  }
  const url = new URL(post_logout_redirect_uri);
  url.searchParams.append('timeout', 'true');
  return {
    post_logout_redirect_uri: url,
  };
};
