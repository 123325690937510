import {
  VtxButton,
  VtxCol,
  VtxDivider,
  VtxInputSearch,
  VtxRow,
} from '@vertexinc/vtx-ui-react-component-library';
import React from 'react';
import 'src/pages/customer-management/shared-customer-search-panel.scss';

const MIN_SEARCH_LENGTH = 1;
const MAX_SEARCH_LENGTH = 50;

interface ICustomerSearchPanel {
  searchValue: string;
  actionButtonText: string;
  shouldDisplayActionButton: boolean;
  searchAriaLabel: string;
  onSearch: (val: string) => void;
  onActionButtonClick: () => void;
}

export const SharedCustomerSearchPanel = ({
  searchValue,
  actionButtonText,
  shouldDisplayActionButton,
  searchAriaLabel,
  onSearch,
  onActionButtonClick,
}: ICustomerSearchPanel) => {
  const handleSearch = (value: string) => {
    if (value.length >= MIN_SEARCH_LENGTH || (value !== searchValue && value === '')) {
      onSearch(value);
    }
  };

  return (
    <VtxRow gutter={8} align="middle" className="customerManagement__panel">
      {shouldDisplayActionButton && (
        <>
          <VtxCol>
            <VtxButton
              data-testid="customer-management-add-button"
              size="middle"
              type="primary"
              onClick={onActionButtonClick}
            >
              {actionButtonText}
            </VtxButton>
          </VtxCol>
          <VtxCol>
            <VtxDivider type="vertical" className="divider" />
          </VtxCol>
        </>
      )}
      <VtxCol span={8} className="customerManagement__search">
        <label htmlFor="search">Search</label>
        <VtxInputSearch
          id="search"
          type="search"
          data-testid="customer-management-search-input"
          allowClear
          maxLength={MAX_SEARCH_LENGTH}
          onBlur={(event) => handleSearch(event.target.value)}
          onSearch={(value) => handleSearch(value)}
          className="customerManagement__search-input"
          aria-label={searchAriaLabel}
        />
      </VtxCol>
    </VtxRow>
  );
};
