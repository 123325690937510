import { VtxIcon } from '@vertexinc/vtx-ui-react-component-library';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { PATHS } from 'src/constants';
import { IOrganization } from 'src/models/organization';
import { UserRole } from 'src/models/user-info';
import { ILoginUser } from 'src/types';
import './side-menu.scss';

interface OwnProps {
  loginUser: ILoginUser;
  organization: IOrganization;
}

export const SideMenu = (props: OwnProps) => {
  const { organization, loginUser } = props;

  return (
    <div className="side-menu">
      <ul>
        <li>
          <Link
            to={generatePath(PATHS.CUSTOMER_INDEX, { orgName: organization })}
            role="menuitem"
            data-testid="customer-management"
          >
            <VtxIcon type="building" /> Customer Management
          </Link>
          {loginUser.userInfo.userRoleId !== UserRole.CustomerAdmin && (
            <ul>
              <li>
                <Link to={generatePath(PATHS.CUSTOMER_ADD, { orgName: organization })} role="menuitem">
                  Invite a Customer
                </Link>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};
