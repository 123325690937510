export const PUBLIC_APP_URL = 'https://www.vertexinc.com';
export const DEFAULT_PAGE_SIZE = 10;

// The backend limit for the Top query is 50
export const TABLE_PAGE_SIZE_OPTIONS = [10, 20, 50];

export const PATHS = {
  ROOT: '/',
  ERROR: '/error',
  NOT_FOUND: '/not-found',
  FORBIDDEN: '/access-denied',
  LOGGED_OUT_ROUTE: '/loggedout',
  OIDC_REDIRECT_RELATIVE: '/authorize',

  CUSTOMER_INDEX: '/:orgName/customers',
  CUSTOMER_ADD: '/:orgName/customers/add',
  CUSTOMER_VIEW: '/:orgName/customers/:id',
  CUSTOMER_VIEW_VOD_DOMAINS: '/:orgName/customers/:id/vod-domains',
  CUSTOMER_VIEW_USERS: '/:orgName/customers/:id/users',
  CUSTOMER_VIEW_CONNECTIONS: '/:orgName/customers/:id/connections',
};

export const QUERY_KEYS = {
  CUSTOMERS: 'customers',
  CUSTOMER: 'customer',
  CUSTOMER_ROLES: 'customerRoles',
  VOD_SUBDOMAINS: 'vodSubDomains',
};
